<template>
  <small class="consent-text">&copy;
    <span>{{ new Date().getFullYear() }}
      <a
        href="https://www.toolsquare.io"
        rel="noopener"
        target="_blank"
      >Toolsquare.io</a></span>
    -
    <button
      class="btn btn-link"
      onclick="window.triggerModal()"
    >
      change cookie preferences
    </button>
    - v{{ version }}
  </small>
</template>

<script setup>
import { useRuntimeConfig } from "#app";

const config = useRuntimeConfig();
const version = config.public.VERSION;
</script>
<script>
export default {
  name: "PublicFooter",
};
</script>
